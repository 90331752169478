<template>
  <div>
    <hero />

    <contents />
  </div>
</template>

<script>
  export default {
    components: {
      Hero: () => import('@/components/home/Hero'),
      Contents: () => import('@/components/home/Contents')
    },

    metaInfo () {
      return {
        title: 'IT Fabrik - Computer programming, Web Development, IT Solutions',
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: 'IT Fabrik doo software and IoT development company. Web development and computer programming.'
          },
          {
            vmid: 'Keywords',
            name: 'Keywords',
            content: 'it, it solutions, programming, web development, internet of things, php, laravel, vue, react, arduino.'
          }
        ],
        link: [
          { vmid: 'canonical', rel: 'canonical', href: 'https://itfabrik.rs' }
        ]
      }
    }
  }
</script>
